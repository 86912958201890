import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import "./Contact.scss";
import { css } from "@emotion/core";
import CMOButton from "../../components/Button/Button";

import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
 
  }
 

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 100px;
      box-shadow: none;
      border: 4px solid #4caf50;
    `;

    const getIconByHref = (href, classes) => {
      if( href.startsWith("tel") )
        return <PhoneIcon className={classes} />
      else 
        return <MailIcon className={classes}/>
    };

    const {
      SEO,
      Title,
      BannerImage,
      Contacts,
      Subtitle,
      CompanyData
    } = this.props.pageContext.content;

    console.log( JSON.stringify( CompanyData, null, 2 ) );

    const location_href = this.props.location.pathname;
    return (
      <LayoutHeaderFooter
        header_background_color={"#801DFF"}
        header_secondary_color={"#FFDDFF"}
        header_no_fade={false}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
        main_class={"contacts-page"}
      >
        <div
          className="top-section"
          style={{
            backgroundImage: `url(${
              process.env.GATSBY_CMS_URL + BannerImage.url
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1>{Title}</h1> 
          <span className="subtitle">{Subtitle}</span>
        </div>
        <div className="contacts-section">
          <h2>Elérhetőségek</h2>
          <div className="contacts-card-container">
            { Contacts.map( (itm, index) => { return (
              <div key={`contact-`+index} className="contacts-card" >
                <span className="contacts-card-icon">{getIconByHref( itm.hrefPrefix, "contacts-card-icon" )}</span>
                <p className="contacts-card-title">{itm.title}</p>
                <p className="contacts-card-description">{itm.description}</p>
                <p className="contacts-card-contact">{itm.contact}</p>
                <p className="contacts-card-availability">{itm.available}</p>

                <a href={itm.hrefPrefix}>
                  <CMOButton
                      icon={getIconByHref( itm.hrefPrefix, null )}
                      variant="contained"
                      background_color={"#5107FF"}
                      text={itm.btnText}
                      color={"white"}
                      onClick={() => {}}
                    ></CMOButton>
              </a>
            </div>
            )} )}
          </div>
        </div>
        <div className="company-section">
          <h2>Cégadatok</h2>
          <div className="company-data-row-container">
            <div className="company-data-row">
              <div className="item-title">Cégnév</div>
              <div className="item-value">{CompanyData.companyName}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">Székhely</div>
              <div className="item-value">{CompanyData.headquarters}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">Kiskereskedelmmel foglalkozó telephely</div>
              <div className="item-value">{CompanyData.PremiseAddress}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">Cégjegyzékszám</div>
              <div className="item-value">{CompanyData.registrationNumber}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">Adószám</div>
              <div className="item-value">{CompanyData.taxNumber}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">Bankszámla vezető bank</div>
              <div className="item-value">{CompanyData.bank}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">Bankszámlaszám</div>
              <div className="item-value">{CompanyData.accountNumber}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">IBAN</div>
              <div className="item-value">{CompanyData.IBAN}</div>
            </div>
            <div className="company-data-row">
              <div className="item-title">SWIFT</div>
              <div className="item-value">{CompanyData.SWIFT}</div>
            </div>
          </div>
        </div>
      </LayoutHeaderFooter>
    );
  }
}

export default ContactPage;
